import {Http} from '@wix/wixstores-client-core/dist/es/src/http/http';
import {gqlQuery} from './getProduct';
import {SiteStore} from '@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/site-store/SiteStore';
import {query as getCartServiceQuery} from '../graphql/getCartService.graphql';
import {graphqlOperation} from '../constants';
import {GetCartServiceQuery} from '../graphql/queries-schema';
import {VolatileCartFromServer} from './volatileCartService.testKit';

export const VolatileCartApiUrl = '/stores/v1/carts/volatileCart';

export class VolatileCartService {
  private _cartId: string;
  private _initialPartialCart: VolatileCartFromServer['cart'];

  constructor(private readonly httpClient: Http, private readonly siteStore: SiteStore) {
    // noop
  }

  public getStandaloneCartId = async (
    productId: string,
    optionSelectionId: number[],
    quantity: number,
    customTextFieldSelection: {title: string; value: string}[],
    subscriptionOptionId?: string
  ): Promise<string> => {
    const response = await this.httpClient.post(VolatileCartApiUrl, {
      productId,
      optionSelectionId,
      customTextFieldSelection,
      quantity,
      subscriptionOptionId,
    });

    this._cartId = response.data.cart.id;
    this._initialPartialCart = response.data.cart;
    return this._cartId;
  };

  public get initialPartialCart() {
    return this._initialPartialCart;
  }

  public getCart = async (): Promise<GetCartServiceQuery> => {
    return (
      await gqlQuery(
        this.siteStore,
        getCartServiceQuery,
        {cartId: this._cartId, locale: this.siteStore.locale},
        graphqlOperation.GetCartService
      )
    ).data;
  };

  public get cartId() {
    return this._cartId;
  }
}
