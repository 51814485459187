import {SPECS as SdkSpecs} from '@wix/wixstores-client-storefront-sdk/dist/es/src/constants';

export const SPECS = {
  ...SdkSpecs,
  CASHIER_EXPRESS_IN_PRODUCT_PAGE: 'specs.stores.CashierExpressInProductPage',
  EDITABLE_HEADING_TAGS: 'specs.stores.EditableHeadingTags',
  OPTIONS_FROM_VARIANT_CALCULATOR: 'specs.stores.OptionsFromVariantCalculatorOnProductPage',
  PRODUCT_PAYMENT_OPTIONS_BREAKDOWN: 'specs.stores.ProductPaymentOptionsBreakdown',
  SUBSCRIPTION_PLAN_USE_TPA_RADIO_BUTTON: 'specs.stores.SubscriptionPlanUseTpaRadioButton',
  URL_CUSTOMIZE: 'specs.stores.EnableUrlCustomizeInProductPage',
  USE_LIGHTBOXES: 'specs.stores.UseLightboxes',
  UI_TPA_COLOR_OPTIONS: 'specs.stores.UiTpaColorPickerOptions',
  BASE_PRICE_IN_GALLERY: 'specs.stores.BasePriceInGallery',
  SELECTABLE_OUT_OF_STOCK: 'specs.stores.SelectableOOSOptionsPP',
};

export enum clientSideBuildCheckoutUrlSpecValues {
  Closed = 'closed',
  Compare = 'compare',
  UseNewBuilder = 'useNewBuilder',
}
